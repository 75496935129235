import React, { useEffect, useState } from 'react';
import BaseComponent from '../BaseComponent';
import "../../styles/components/plane.css";

function Aid() {

  const [wrap, setWrap] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 984 ? setWrap("flex-wrap") : setWrap("");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
      <BaseComponent language="hu">
        <section className={`container-fluid plane d-flex ${wrap} flex-row justify-content-between`} style={{ width: "70%" }}>
          <div className="d-flex flex-column">
            <h2 style={{ fontSize: "1.5em" }}>Komplex infokommunikációs fejlesztés a Kob-Flex Kft-nél</h2>
            <p><b>GINOP-3.2.2-8-2-4-16-2017-00191</b></p>
            <p>Kedvezményezett: KOB-FLEX Irányítástechnikai, Automatizálási Szolgáltató Korlátolt Felelősségű Társaság</p>
          </div>
          <img alt="EU Fund Logo" className="d-flex" src="/images/eu/eu-hu-felso.png" style={{maxHeight: "200px"}}/>
        </section>
        <section className="container-fluid plane d-flex flex-column justify-content-center p-3 mb-5" style={{ width: "70%" }}>
          <table class="table table-hover mb-3">
            <tbody>
              <tr>
                <th scope="row">Támogatás összege:</th>
                <td>6.644.280 Ft</td>
              </tr>
              <tr>
                <th scope="row">Támogatás mértéke:</th>
                <td>40%</td>
              </tr>
              <tr>
                <th scope="row">A projekt befejezése:</th>
                <td colspan="2">2019.03.29.</td>
              </tr>
            </tbody>
          </table>
          <span>A KOB-FLEX Kft-nél 7 modulból álló vállalatirányítási rendszer került bevezetésre a pályázat
            keretében. A pályázati támogatás segítségével megtörtént a vállalkozás technológiai hátterének és
            üzleti folyamatának modernizálása, javult a versenyképességünk. A szoftvernek köszönhetően
            megvalósult a munkafolyamatok összegyűjtése, rendszerezése, ellenőrizhetősége. A rendszer
            naprakész és pontos vezetői információkat biztosít. Egyszerűsíti és részben automatizálja az
            adminisztratív folyamatokat. A vállalkozás releváns piacon elfoglalt pozíció alakulása erősödik, hiszen
            nagyobb hatékonysággal valósul meg az ügyfélkiszolgálás, ami növekvő vevői elégedettséget jelent. A
            pályázat keretében az alábbi eszközbeszerzés történt:

            <table class="table table-hover mt-3">
              <thead>
                <tr>
                  <th scope="col">Eszköz</th>
                  <th scope="col">Mennyiség (db)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>QNAP TVS-473e-4G NAS szerver</td>
                  <td>1 db</td>
                </tr>
                <tr>
                  <td>Dell PowerEdge T130</td>
                  <td>1 db</td>
                </tr>
                <tr>
                  <td>Microsoft Windows Server CAL 2016</td>
                  <td colspan="2">1 db</td>
                </tr>
                <tr>
                  <td>Dell Windows Server 2016 Standard Edition</td>
                  <td colspan="2">1 db</td>
                </tr>
                <tr>
                  <td>Acer Aspire Nitro</td>
                  <td colspan="2">2 db</td>
                </tr>
              </tbody>
            </table>
          </span>
        </section>
      </BaseComponent>
    </>
  );
}

export default Aid;
import React, { useEffect, useState } from 'react';
import BaseComponent from '../BaseComponent';
import "../../styles/components/plane.css";

function QualityAssurance() {
  const [wrap, setWrap] = useState(false);
  const classesImage = "flex-fill";
  const styleImage = { width: "100%", height: "100%" };

  const classesContainerLink = "plane align-items-center";
  const styleContainerLink = { width: "30%", height: "30%", minHeight: "30%", maxHeight: "30%" };

  const sectionClasses = (wrap) => `container-fluid d-flex ${wrap} align-items-center justify-content-center gap-3 p-3 mb-5`;

  const qaFiles = [
    "Tanusitvany",
    "IQNET-KOB-FLEX-MIR",
    "MK_2Minosegpolitika 3v XXX",
    "MSZT-KOB-FLEX-MIR"
  ];

  const gdprFiles = [
    "GDPR_external_2020",
    "GDPR_internal_2020"
  ];

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 984 ? setWrap("flex-wrap") : setWrap("");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <BaseComponent language="hu">
        <section className={`container-fluid d-flex flex-column align-items-center  p-3 mt-5`} style={{ width: "70%" }}>
          <p className="align-text-center" style={{ fontSize: "1.5em", fontWeight: "bold" }}>Adatvédelmi Tájékoztató</p>
          <p className="align-text-center" style={{ fontSize: "1.5em", fontStyle: "italic" }}>Data Privacy Policy</p>
        </section>
        <section className={sectionClasses(wrap)} style={{ width: "70%" }}>
          {
            gdprFiles.map(
              title => (
                <a className={classesContainerLink} href={`/legal/${title}.pdf`} style={styleContainerLink}>
                  <img className={classesImage} src={`/legal/${title}.jpg`} alt="PDF Thumbnail" style={styleImage} />
                </a>
              )
            )
          }

        </section>
        <section className={`container-fluid d-flex flex-column align-items-center  p-3`} style={{ width: "70%" }}>
          <p className="align-text-center" style={{ fontSize: "1.5em", fontWeight: "bold" }}>Minőségpolitika</p>
          <p className="align-text-center" style={{ fontSize: "1.5em", fontStyle: "italic" }}>Quality Assurance</p>
        </section>
        <section className={sectionClasses(wrap)} style={{ width: "70%" }}>
          {
            qaFiles.map(
              title => (
                <a className={classesContainerLink} href={`/qa/${title}.pdf`} style={styleContainerLink}>
                  <img className={classesImage} src={`/qa/${title}.jpg`} alt="PDF Thumbnail" style={styleImage} />
                </a>
              )
            )
          }

        </section>
      </BaseComponent>
    </>
  );
}

export default QualityAssurance;
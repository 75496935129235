export const content = {
    en:[
        {
            "title": "Hungary",
            "text": [
                "installation and operation of PLC-SCADA automation systems in major dairy and brewery plants",
                "pharmaceutical instrumentation, electrical installation in explosive environments, and full automation of custom hygienic technologies",
                "development and installation of production control systems for other FMCG manufacturing plants",
                "development of control systems for automotive, cladding material manufacturing, and reinforced concrete manufacturing plants"
            ],
            "image": ["dairy", "beer", "medicine", "automotive", "construction", "soda"]
        },
        {
            "title": "Ukraine",
            "text": [
                "automation of two complete dairy factories",
                "installation of other unique, smaller automation systems"
            ],
            "image": ["dairy"]
        },
        {
            "title": "Romania",
            "text": [
                "automation of a dairy-based baby food factory",
                "automation of a mineral water bottling plant",
                "installation of other smaller automation systems"
            ],
            "image": ["dairy", "water"]
        },
        {
            "title": "Serbia and Bosnia-Herzegovina",
            "text": [
                "automation of milk pasteurizers"
            ],
            "image": ["dairy"]
        },
        {
            "title": "Croatia and Slovenia",
            "text": [
                "installation of complex dairy applications"
            ],
            "image": ["dairy"]
        },
        {
            "title": "Slovakia",
            "text": [
                "development of control technology based on Rockwell for one of the largest beer production plants"
            ],
            "image": ["beer"]
        },
        {
            "title": "Switzerland and France",
            "text": [
                "software development related to chemical filtration systems"
            ],
            "image": ["chemical"]
        },
        {
            "title": "Spain, England, Kuwait",
            "text": [
                "various smaller projects in numerous countries"
            ],
            "image": ["dairy", "beer", "soda"]
        }
    ],
    hu: [
        {
            title: "Magyarország",
            text: [
                "a jelentősebb tej- és söripari üzemekben PLC-SCADA automatika rendszerek telepítése és üzemeltetése",
                "gyógyszeripari műszerezés, robbanásveszélyes környezetben történő villamos telepítés és speciális higiénikus technológiák teljes körű automatizálása",
                "egyéb FMCG gyártó üzemek termelésirányító rendszereinek fejlesztése és telepítése",
                "autóipari, burkolóanyaggyártó, vasbeton gyártó üzemek irányítástechnikai rendszereinek fejlesztése"
            ],
            image: ["dairy", "beer", "medicine", "automotive", "construction", "soda"]
        },
        {
            title: "Ukrajna",
            text: [
                "kettő komplett tejgyár automatizálása",
                "egyéb egyedi, kisebb automatika rendszerek telepítése"
            ],
            image: ["dairy"]
        },
        {
            title: "Románia",
            text: [
                "tej alapú bébiétel gyár automatizálása",
                "ásványvíz palackozó üzem automatizálása",
                "egyéb egyedi, kisebb automatika rendszerek telepítése"
            ],
            image: ["dairy", "water"]
        },
        {
            title: "Szerbia és Bosznia-Hercegovina",
            text: [
                "tejpasztőrök automatizálása"
            ],
            image: ["dairy"]
        },
        {
            title: "Horvátország és Szlovénia",
            text: [
                "komplex tejipari alkalmazások telepítése"
            ],
            image: ["dairy"]
        },
        {
            title: "Szlovákia",
            text: [
                "egyik legnagobb sörtermelő üzem Rockwell alapú irányítástechnikai fejlesztése"
            ],
            image: ["beer"]
        },
        {
            title: "Svájc és Franciaország",
            text: [
                "vegyipari szűrőrendszerekhez kapcsolódó szoftverfejlesztés"
            ],
            image: ["chemical"]
        },
        {
            title: "Spanyolország, Anglia, Kuwait",
            text: [
                "egyéb kisebb projektek számos országban"
            ],
            image: ["dairy", "beer", "soda"]
        }
    ]
}

export default content;
import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';

function BaseComponent({ children, language }) {

  const [distanceFromBottom, setDistanceFromBottom] = useState(0);
  const [logoSwitch, setLogoSwitch] = useState("also");

  useEffect(() => {
    const handleFixedImage = () => {
      const footer = document.querySelector("footer") && document.querySelector("footer").getBoundingClientRect();
      const viewport = window.innerHeight;
      const distance = viewport - footer.top;
      if (distance >= 0) {
        setDistanceFromBottom(distance);
      } else {
        setDistanceFromBottom(0);
      }
      if (distance * 2 > viewport && logoSwitch.includes("also")) {
        console.log(logoSwitch);
        setLogoSwitch("felso");
      } else if (distance * 2 <= viewport && logoSwitch.includes("felso")) {
        console.log(logoSwitch);
        setLogoSwitch("also");
      }
    };
    handleFixedImage();
    window.addEventListener("scroll", handleFixedImage);
    window.addEventListener("resize", handleFixedImage);
    return () => {
      window.removeEventListener("scroll", handleFixedImage);
      window.removeEventListener("resize", handleFixedImage);
    };
  }, [logoSwitch]);

  return (
    <>
      <Header language={language} />
      {children}
      <img alt="EU Fund Logo" src={`/images/eu/eu-${language}-${logoSwitch}.png`} id="eu-logo" className="eu-logo" style={{ zIndex: 1000, maxHeight: "200px", maxWidth: "50%", bottom: logoSwitch.includes("felso") ? "auto" : `${distanceFromBottom}px`, top: logoSwitch.includes("felso") ? "0px" : "auto", right: "0px", position: "fixed" }} />
      <Footer language={language} />
    </>
  );
}

export default BaseComponent;
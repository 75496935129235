import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/pages/Home';
import Aid from './components/pages/Aid';
import Legal from './components/pages/Legal';
import QualityAssurance from './components/pages/QA';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route exact path="/" element={<Home manualLanguage="hu"/>} />
          <Route exact path="/en" element={<Home manualLanguage="en"/>} />

          <Route exact path="/:lang/about" element={<Home section="about" />} />
          <Route exact path="/:lang/portfolio" element={<Home section="work" />} />
          <Route exact path="/:lang/contact" element={<Home section="contact" />} />

          <Route exact path="/:lang/legal" element={<Legal />} />

          <Route exact path="/eu-policy" element={<Aid />} />
          <Route exact path="/docs" element={<QualityAssurance />} />

          <Route exact path="/hu" element={<Navigate to={"/"} replace />} />
          <Route exact path="/about" element={<Navigate to={"/hu/about"} replace />} />
          <Route exact path="/portfolio" element={<Navigate to={"/hu/portfolio"} replace />} />
          <Route exact path="/contact" element={<Navigate to={"/hu/contact"} replace />} />
          <Route exact path="/legal" element={<Navigate to={"/hu/legal"} replace />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;

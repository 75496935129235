import React, { useEffect, useState } from 'react';
import BaseComponent from '../BaseComponent';
import Plane from '../items/Plane';
import content from '../../locales/about-us-cards';
import { content as workContent } from '../../locales/work-cards';
import Accordion from '../items/Accordion';
import SmallPlane from '../items/SmallPlane';
import IconContainer from '../items/IconContainer';
import { useParams } from 'react-router-dom';

function Home({ section, manualLanguage }) {

  const { lang }  = useParams();
  const language  = lang ||  manualLanguage;

  useEffect(() => {
    if (section) {
      const element = document.getElementById(`section-logo-${section}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
      }
    }
  }, [section]);

  return (
    <>
      <BaseComponent language={language}>
        <section onLoad={() => section && document.getElementById(`section-logo-${section}`)?.scrollIntoView()} className="container-fluid p-3" style={{ width: "70%" }}>
          <Plane image={`small/banner-${language}.jpg`}/>
          <img alt="About us / Rólunk" src={`/images/sections/about_${language}.png`} id="section-logo-about" className="section-logo mb-4" style={{ maxHeight: "150px", maxWidth: "100%" }} />
          <Accordion theme="about"  contents={content[language]} />
          <img alt="Our work / Munkáink" src={`/images/sections/work_${language}.png`} id="section-logo-work" className="section-logo mb-4" style={{ maxHeight: "150px", maxWidth: "100%" }} />
          <Plane image="map.png"/>
          <IconContainer lang={language} type="trans-grey" icons={["dairy", "beer", "medicine", "soda", "water", "construction", "chemical", "automotive"]}/>
          <Accordion theme="work"  contents={workContent[language]} />
          <img alt="Contact / Kontakt" src={`/images/sections/contact_${language}.png`} id="section-logo-contact" className="section-logo mb-4" style={{ maxHeight: "150px", maxWidth: "100%" }} />
          <SmallPlane lang={language} />
        </section>
      </BaseComponent>
    </>
  );
}

export default Home;
import React from "react";
import Icon from "./Icon";
import "../../styles/components/icon-container.css";
import { colors } from "../../styles/colorPalette";

function IconContainer({ lang, type, icons }) {

    return (
        <div className={`icon-container d-flex flex-column p-auto m-auto mb-4 justify-content-center`} id={`icon-container`}>
            <span style={{alignSelf: "center", fontWeight: "bold", color: colors.grey.dark}} className="d-flex mb-2 position-relative">{lang === "hu" ? "Szakterületeink" : "Our areas of expertise"}</span>
            <span className={`d-flex flew-row justify-content-between`} style={{overflowX: "auto", scrollSnapAlign: "start"}}>{icons.map(icon => <Icon key={icon} lang={lang} type={type} icon={icon}/>)}</span>
        </div>
    );
}

export default IconContainer;
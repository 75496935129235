export const content = {
    "hu": {
        openingName: "Nyitvatartás",
        openingValue: "hétfőtől péntekig, 8 és 16 óra között",
        primaryPhoneName: "Telefon",
        primaryPhoneValue: "+36 33 504 410",
        primaryEmailName: "E-mail",
        primaryEmailValue: "iroda@kob-flex.hu"

    },
    "en": {
        openingName: "Opening Hours",
        openingValue: "Monday to Friday, 8:00 am until 4:00 pm",
        primaryPhoneName: "Phone",
        primaryPhoneValue: "+36 33 504 410",
        primaryEmailName: "E-mail",
        primaryEmailValue: "iroda@kob-flex.hu"
    }
}